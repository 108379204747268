import { Component, OnInit, inject } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { BaseComponent } from '../../../shared/components/base-component/base-component.component';
import { ActivatedRoute, Router } from '@angular/router';
import { VincularContaService } from './vincular-conta.service';
import { ProcessarLinkVinculoParams } from '../vincular-conta.model';
import { CriptografiaUtil } from '../../../shared/extensions/criptografia.extensions';
import { AutenticacaoService } from '../../autenticacao/autenticacao.service';

@Component({
  selector: 'app-verificar-instituicao',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './verificar-instituicao.component.html',
  styleUrl: './verificar-instituicao.component.scss'
})
export class VerificarInstituicaoComponent extends BaseComponent implements OnInit {

  private _vincularContaService = inject(VincularContaService);
  private _router = inject(Router);
  private _activatedRoute = inject(ActivatedRoute);
  private _autenticacaoService = inject(AutenticacaoService);

  /**
   * Este endpoint responde apenas os casos onde o escope de token está informando 
   * e que o escopo de interação seja 'link' do contrário o usuário será enviado para o login 
   * sem escopo de vínculo definido.
   */
  ngOnInit() {
    this._autenticacaoService.logout(true);
    this.processarQueryParams(this._activatedRoute);
    if (this.scope_token != null && this.scope_action == 'link') {
      this.processarLinkVinculo();
    } else {
      this._router.navigate(['/autenticacao/login'], { queryParamsHandling: 'preserve' });
    }
  }

  private processarLinkVinculo() {
    this.enableBusy('Estamos verificando a instituição, aguarde...');
    const params: ProcessarLinkVinculoParams = {
      token: this.scope_token!
    };

    this._vincularContaService.processarLinkVinculo(params)
      .subscribe({
        next: (p) => {
          /**
             * Uma vez identificado que o usuário já existe, ele é direcionado para 
             * o processo de vinculação de contas já com o escopo de instituição de usuário definidos na URL.
             */
          if (p.data.usuario) {
            this.setBusyMessage('Instituição verificada, aguarde só mais um instante...');
            this._router.navigate(['/vincular-conta/vincular-instituicao'], {
              queryParamsHandling: 'merge',
              queryParams: {
                scope_ins: CriptografiaUtil.crypt64(p.data.organizacao),
                scope_usr: CriptografiaUtil.crypt64(p.data.usuario),
                scope_token: null
              }
            });
          } else {
            /**
             * Se identificado que o usuário não existe na base, ele é direcionado para 
             * o processo de criação de conta mantendo o escopo de instituição na URL.
             */
            this._router.navigate(['/autenticacao/criar-conta'], {
              queryParamsHandling: 'merge',
              queryParams: {
                scope_ins: CriptografiaUtil.crypt64(p.data.organizacao),
                scope_usr: null,
                scope_token: null
              }
            });
          }
        },
        error: (err) => {
          this.setBusyMessage(err.error.message);
          this.setBusyIcon(false);
        }
      });
  }
}
