<app-animated-container>
    <section class="auth-wrapper">
        <div class="auth-inner">
            <div class="card">
                <div class="card-body" style="padding: 20px;">
                    <div class="row">
                        <div class="col-md-12" *ngIf="isBusy.active">
                            <div class="logo-header-no-text pt-3">
                                <img src="../../../../assets/images/logo_vertical.png" />
                            </div>
                            <h1 class="h-title">
                                Vincular à conta Perseus
                            </h1>

                            <h2 class="h-subtitle pt-3 pb-0 mt-2">
                                {{isBusy.message}}
                            </h2>
                            <div class="text-center" *ngIf="isBusy.showIcon">
                                <span class="spinner-grow spinner-grow-sm text-primary" role="status"
                                    aria-hidden="true"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</app-animated-container>