import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpRequestService } from '../../../core/services/http-request/http-request.service';
import { ResponseBase, Response } from '../../../core/models/response.model';
import { OrganizacaoVinculoModel, ProcessarLinkVinculoParams, ProcessarLinkVinculoResult, SolicitarVinculoParams, UsuarioVinculoModel } from '../vincular-conta.model';

@Injectable({
    providedIn: 'root'
})
export class VincularContaService {

    constructor(private _httpRequest: HttpRequestService) { }

    /**
     * Requisição HTTP para verificar e processar o token de vínculo recebido da organização
     */
    processarLinkVinculo(params: ProcessarLinkVinculoParams): Observable<Response<ProcessarLinkVinculoResult>> {
        return this._httpRequest.post<Response<ProcessarLinkVinculoResult>>('/v1/organizacao/processar-link-vinculo-conta', params);
    }

    /**
     * Requisição HTTP para enviar código de verificação ao usuário solicitar a conclusão do vínculo
     */
    solicitarVinculo(params: SolicitarVinculoParams): Observable<ResponseBase> {
        return this._httpRequest.post<ResponseBase>('/v1/organizacao/solicitar-vinculo-conta', params);
    }
}
