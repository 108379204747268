import { HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { AutenticacaoService } from "../../features/autenticacao/autenticacao.service";
import { JwtHelperService } from "@auth0/angular-jwt";
import { catchError, switchMap, throwError } from "rxjs";

@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {
    private jwtHelper: JwtHelperService = new JwtHelperService();
    private isRefreshingToken = false;
    private _autenticacaoService = inject(AutenticacaoService);

    intercept(pRequest: HttpRequest<any>, pNext: HttpHandler) {
        let lRequest = pRequest.clone({
            headers: pRequest.headers.set('Content-Type', 'application/json'),
        });

        let lInfoUsuario = this._autenticacaoService.obterDadosUsuarioLogado();

        if (lInfoUsuario) {
            if (!this.jwtHelper.isTokenExpired(lInfoUsuario.token)) {
                lRequest = this.atribuirJwtToken(lRequest, lInfoUsuario.token);
            } else {
                return this.handleRefreshToken(lRequest, pNext);
            }
        }

        return pNext.handle(lRequest);
    }

    private atribuirJwtToken(pRequest: HttpRequest<any>, psToken: string): HttpRequest<any> {
        return pRequest.clone({
            headers: pRequest.headers.set(
                'Authorization',
                'Bearer ' + psToken
            ),
        });
    }

    private handleRefreshToken(pRequest: HttpRequest<any>, pNext: HttpHandler) {
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;

            return this._autenticacaoService.renovarToken().pipe(
                switchMap((pResponse) => {
                    this.isRefreshingToken = false;
                    const lRequest = this.atribuirJwtToken(pRequest, pResponse.data.token);
                    return pNext.handle(lRequest);
                }),
                catchError(() => {
                    this.isRefreshingToken = false;
                    return this.handleLogout();
                })
            );
        }

        return pNext.handle(pRequest);
    }

    private handleLogout() {
        this._autenticacaoService.logout();
        return throwError(
            () =>
                new Error(
                    'Suas credenciais de acesso expiraram, é necessário fazer fazer login novamente.'
                )
        );
    }
}
