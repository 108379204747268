import { Routes } from '@angular/router';
import { VerificarInstituicaoComponent } from './features/vincular-conta/verificar-instituicao/verificar-instituicao.component';
import { RouterExtensions } from './shared/extensions/routes.extensions';
import { VincularInstituicaoComponent } from './features/vincular-conta/vincular-instituicao/vincular-instituicao.component';

export const APP_ROUTES: Routes = [
    {
        path: '',
        loadChildren: () => import('./features/features.routes').then(m => m.FEATURE_ROUTES)
    },
    {
        path: 'vincular-conta/verificar-instituicao',
        title: RouterExtensions.processaTituloPagina('Verificar instituição'),
        component: VerificarInstituicaoComponent
    },
    {
        path: 'vincular-conta/vincular-instituicao',
        title: RouterExtensions.processaTituloPagina('Vincular instituição'),
        component: VincularInstituicaoComponent
    },
    { path: '**', redirectTo: '' }
];


