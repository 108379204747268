<app-animated-container>
    <section class="auth-wrapper">
        <div class="auth-inner">
            <div class="card">
                <div class="card-body" style="padding: 20px;">
                    <form class="row" [formGroup]="form!">
                        <div class="col-md-12" *ngIf="isBusy.active">
                            <div class="logo-header-no-text pt-3">
                                <img src="../../../../assets/images/logo_vertical.png" />
                            </div>
                            <h1 class="h-title">
                                Vincular à conta Perseus
                            </h1>

                            <h2 class="h-subtitle pt-3 pb-0 mt-2">
                                {{isBusy.message}}
                            </h2>
                            <div class="text-center" *ngIf="isBusy.showIcon">
                                <span class="spinner-grow spinner-grow-sm text-primary" role="status"
                                    aria-hidden="true"></span>
                            </div>
                        </div>
                        <div class="col-md-12" *ngIf="!isBusy.active">
                            <div class="logo-header-no-text pt-3">
                                <img src="../../../../assets/images/logo_vertical.png" />
                            </div>
                            <h1 class="h-title">
                                Vincular à sua conta Perseus
                            </h1>
                            <div class="ins-info">
                                <div class="ins-label">Instituição:</div>
                                <div class="ins-name">{{ form.controls.instituicaoNome.value }}</div>
                            </div>

                            <h2 class="h-subtitle pt-3 pb-2 mt-2">
                                Para vincular essa instituição, informe
                                o código enviado para o e-mail <span
                                    class="ins-email">{{form.controls.usuarioEmail.value}}</span>.
                            </h2>
                        </div>
                        <div class="row" *ngIf="!isBusy.active">
                            <div class="col-md-12 mb-3 mt-2">
                                <input type="text" class="form-control text-center" placeholder="Código de autorização"
                                    formControlName="token" id="token">
                                <div class="invalid-feedback" *ngIf="form.controls.token.hasError('required')">
                                    Informe o código de verificação
                                </div>
                            </div>
                            <div class="col-md-12 mb-3">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="autorizaVinculo"
                                        formControlName="autorizaVinculo">
                                    <label class="form-check-label" for="autorizaVinculo">
                                        Autorizo o envio dos meus dados à instituição, para fins de acesso aos
                                        produtos por ela oferecidos.
                                    </label>
                                    <div class="invalid-feedback mt-0"
                                        *ngIf="form.controls.autorizaVinculo.hasError('requiredTrue')">
                                        Para continuar é necessário aceitar os termos
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="d-grid gap-2">
                                    <button type="button" class="btn btn-primary" (click)="vincular()"><span
                                            *ngIf="!form.enabled" class="spinner-grow spinner-grow-sm" role="status"
                                            aria-hidden="true"></span> Vincular
                                        instituição</button>
                                </div>

                            </div>
                        </div>
                        <div class="row text-center mt-3" *ngIf="!isBusy.active">
                            <div class="col-lg-12"><a [routerLink]="['/autenticacao/login']"
                                    [queryParams]="{scope_usr: null}" queryParamsHandling="merge"
                                    [disableLink]="!form.enabled">Quero utilizar outra conta</a></div>
                        </div>
                    </form>
                </div>
                <div class="card-footer mb-0 pb-0 mt-3" *ngIf="!isBusy.active">
                    <app-footer></app-footer>
                </div>
            </div>
        </div>
    </section>
</app-animated-container>