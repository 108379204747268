import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { datadogLogs, StatusType } from '@datadog/browser-logs';
import { environment } from '../../../../environments/environment';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class LogService {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if (environment.production && isPlatformBrowser(this.platformId)) {
      datadogLogs.init(environment.datadogLogsConfig);
    }
  }

  info(message: string, data?: any) {
    this.emitLog(message, StatusType.info, data);
  }

  warning(message: string, data?: any) {
    this.emitLog(message, StatusType.warn, data);
  }

  error(message: string, exception: any, data?: any) {
    this.emitLog(message, StatusType.error, data, exception);
  }

  private emitLog(
    message: string,
    status: StatusType,
    data?: any,
    exception?: any
  ) {
    if (environment.production && isPlatformBrowser(this.platformId)) {
      datadogLogs.logger.log(message, data, status, exception);
    }
  }
}
